import React from 'react';
import { useTranslation } from 'next-i18next';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { BROWSING_LINKS } from './constants';
import { HeroCarousel } from './cms-atoms/HeroCarousel';

export const WelcomeHeroCarousel = () => {
    const { t } = useTranslation();

    return (
        <HeroCarousel
            carouselImages={[
                {
                    src: getCdnUrl('/cms/dec2024/vero_synapse_banner_12132024_144_338.jpg'),
                    alt: t('cms:headlines.vero_drop'),
                    link: {
                        url: BROWSING_LINKS.bladebinge_shop,
                        text: t('cms:headlines.vero_drop')
                    }
                },
                {
                    src: getCdnUrl('/cms/bladebinge_weve_got_what_youre_looking_for_banner_1400_338.jpg'),
                    alt: ''
                    // link: {
                    //     url: '',
                    //     text: ''
                    // }
                }
                // {
                //     src: getCdnUrl('/cms/bladebinge_your_collection_wants_a_new_friend_banner_144_338.png'),
                //     alt: ''
                //     // link: {
                //     //     url: '',
                //     //     text: ''
                //     // }
                // },
            ]}
        />
    );
};
