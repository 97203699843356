import { ParsedUrlQuery } from 'querystring';

export const queryWithFilteredParams = ({
    filteredParams,
    query
}: {
    filteredParams: string[];
    query: ParsedUrlQuery;
}) =>
    Object.entries(query).reduce<ParsedUrlQuery>(
        (cleanedQuery, [key, value]) => {
            if (filteredParams.includes(key)) {
                return cleanedQuery;
            }

            cleanedQuery[key] = value;
            return cleanedQuery;
        },
        {} as unknown as ParsedUrlQuery
    );
