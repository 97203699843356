import React from 'react';
import { useTranslation } from 'next-i18next';
import type { CmsComponentProps } from '@bladebinge/types';
import { CmsPlacement } from './CmsPlacement';
import { BROWSING_LINKS } from './constants';

const addLimitToLink = (link: string, listingPagingLimit?: number) =>
    `${link}${listingPagingLimit ? `&limit=${listingPagingLimit}` : ''}`;

export const BrowsingJumpTiles = ({
    borderWidth = '4px',
    gridWidthXs = 6,
    gridWidthSm = 6,
    gridWidthMd = 6,
    headingSx = {},
    subheadingSx = {},
    listingPagingLimit
}: {
    readonly borderWidth?: '1px' | '2px' | '4px';
    readonly gridWidthXs?: CmsComponentProps['gridWidthXs'];
    readonly gridWidthSm?: CmsComponentProps['gridWidthSm'];
    readonly gridWidthMd?: CmsComponentProps['gridWidthMd'];
    readonly headingSx?: { [jsonKey: string]: unknown };
    readonly subheadingSx?: { [jsonKey: string]: unknown };
    readonly listingPagingLimit?: number;
}) => {
    const { t } = useTranslation();

    const CMS_SQUARE_BORDER = {
        borderWidth,
        borderColor: 'theme.palette.divider',
        borderStyle: 'solid'
    };

    return (
        <>
            <CmsPlacement
                aspectRatio={1}
                backgroundImageUrl="/logos/bb_shop_grid_ad_425_425.jpg"
                gridWidthXs={gridWidthXs}
                gridWidthSm={gridWidthSm}
                gridWidthMd={gridWidthMd}
                linkTitle={t('cms:browsing_categories.brand_new_knives_from_bladebinge')}
                primaryLinkUrl={BROWSING_LINKS.bladebinge_shop}
                seoHeadingTranslationKey="cms:seo_headings.shop_new_gear"
                textColor="white"
                headingSx={headingSx}
                subheadingSx={subheadingSx}
                sx={{
                    backgroundColor: '#000000',
                    color: '#ffffff',
                    backgroundSize: 'contain',
                    ...CMS_SQUARE_BORDER
                }}
            />
            <CmsPlacement
                aspectRatio={1}
                backgroundImageUrl="/cms/nov2024/shopmarketplace.jpg"
                gridWidthXs={gridWidthXs}
                gridWidthSm={gridWidthSm}
                gridWidthMd={gridWidthMd}
                linkTitle={t('cms:browsing_categories.shop_marketplace')}
                primaryLinkUrl={addLimitToLink(BROWSING_LINKS.new_listings, listingPagingLimit)}
                seoHeadingTranslationKey="cms:seo_headings.shop_marketplace"
                subheadingTranslationKey="cms:browsing_categories.shop_marketplace"
                textColor="primary.contrastText"
                headingSx={headingSx}
                subheadingSx={subheadingSx}
                sx={CMS_SQUARE_BORDER}
            />
            <CmsPlacement
                aspectRatio={1}
                backgroundImageUrl="/cms/nov2024/grails.jpg"
                gridWidthXs={gridWidthXs}
                gridWidthSm={gridWidthSm}
                gridWidthMd={gridWidthMd}
                linkTitle={t('cms:browsing_categories.grails')}
                primaryLinkUrl={addLimitToLink(BROWSING_LINKS.grails, listingPagingLimit)}
                seoHeadingTranslationKey="cms:seo_headings.shop_grails"
                subheadingTranslationKey="cms:browsing_categories.grails"
                textColor="primary.contrastText"
                headingSx={headingSx}
                subheadingSx={subheadingSx}
                sx={CMS_SQUARE_BORDER}
            />
            <CmsPlacement
                aspectRatio={1}
                backgroundImageUrl="/cms/nov2024/edcaccessories.jpg"
                gridWidthXs={gridWidthXs}
                gridWidthSm={gridWidthSm}
                gridWidthMd={gridWidthMd}
                primaryLinkUrl={addLimitToLink(BROWSING_LINKS.accessories, listingPagingLimit)}
                seoHeadingTranslationKey="cms:seo_headings.shop_edc_accessories"
                subheadingTranslationKey="cms:browsing_categories.edc_accessories"
                textColor="primary.contrastText"
                headingSx={headingSx}
                subheadingSx={subheadingSx}
                sx={CMS_SQUARE_BORDER}
            />
            <CmsPlacement
                aspectRatio={1}
                backgroundImageUrl="/cms/nov2024/madeinUSA.jpg"
                gridWidthXs={gridWidthXs}
                gridWidthSm={gridWidthSm}
                gridWidthMd={gridWidthMd}
                linkTitle={t('cms:browsing_categories.made_in_usa')}
                primaryLinkUrl={addLimitToLink(BROWSING_LINKS.made_in_usa, listingPagingLimit)}
                seoHeadingTranslationKey="cms:seo_headings.shop_made_in_usa"
                subheadingTranslationKey="cms:browsing_categories.made_in_usa"
                textColor="primary.contrastText"
                headingSx={headingSx}
                subheadingSx={subheadingSx}
                sx={CMS_SQUARE_BORDER}
            />
            <CmsPlacement
                aspectRatio={1}
                backgroundImageUrl="/cms/nov2024/budgetfriendly.jpg"
                gridWidthXs={gridWidthXs}
                gridWidthSm={gridWidthSm}
                gridWidthMd={gridWidthMd}
                linkTitle={t('cms:browsing_categories.budget_friendly')}
                primaryLinkUrl={addLimitToLink(BROWSING_LINKS.budget_friendly, listingPagingLimit)}
                seoHeadingTranslationKey="cms:seo_headings.shop_budget_friendly"
                subheadingTranslationKey="cms:browsing_categories.budget_friendly"
                textColor="primary.contrastText"
                headingSx={headingSx}
                subheadingSx={subheadingSx}
                sx={CMS_SQUARE_BORDER}
            />
        </>
    );
};
