export const BROWSING_LINKS = {
    accessories: '/browse?searchFilters=174',
    // american_blade_works: '/browse?filterMode=ANY&searchFilters=40&username=bladebinge',
    budget_friendly: '/browse?filterMode=ANY&searchFilters=183,184&sortBy=price&sortDirection=ASC',
    // divo_knives: '/browse?filterMode=ANY&searchFilters=259&username=bladebinge',
    // giant_mouse: '/browse?searchFilters=72&username=bladebinge',
    bladebinge: '/browse?username=bladebinge',
    bladebinge_divo: '/browse?searchFilters=259&username=bladebinge',
    bladebinge_hogue: '/browse?searchFilters=78&username=bladebinge',
    grails: '/browse?searchFilters=187,188',
    bladebinge_shop: 'https://bladebingeshop.com',
    bladebing_shop_lander_3:
        'https://bladebingeshop.com/collections/new-arrivals/products/bladebinge-exclusive-dark-mode-lander-3',
    bladebinge_shop_combat_beads: 'https://bladebingeshop.com/collections/combat-beads-by-griffin-co',
    bladebinge_shop_divo_knives: 'https://bladebingeshop.com/collections/divo-knives',
    bladebinge_shop_dark_mode_collabs: 'https://bladebingeshop.com/collections/dark-mode-collabs',
    bladebinge_shop_null_knives: 'https://bladebingeshop.com/collections/null-knives',
    blade_show_atl: 'https://bladeshow.com/home/',
    cdc_hanson: 'https://bladebingeshop.com/products/crispy-donut-community-dark-mode-hanson-w-xl',
    made_in_usa: '/browse?filterMode=ANY&searchFilters=177',
    premium_listings: '/browse?filterMode=ANY&searchFilters=186,187,188&sortBy=price&sortDirection=DESC',
    new_listings: '/browse?sortBy=sortDate&sortDirection=DESC'
    // urban_edc: '/browse?searchFilters=264,265&username=bladebinge',
    //     used_listings: '/browse?filterMode=ANY&searchFilters=169,170,171,172,173'
};
