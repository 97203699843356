import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import type { OrderByWithLabel, SortingQueryParamMapping } from '@bladebinge/types';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

export const SortingOptionsSelector = ({
    defaultValue = '', // should be a comma delimited string to avoid rerender loops
    sortingOptions,
    onSortChange
}: {
    readonly defaultValue?: string;
    readonly onSortChange: (orderedSortOptions: SortingQueryParamMapping) => void;
    readonly sortingOptions: OrderByWithLabel[];
}) => {
    const { t } = useTranslation();
    const [isSortSelectorOpen, setIsSortSelectorOpen] = useState<boolean>(false);
    const [sortValue, setSortValue] = useState<string[]>(defaultValue.split(',').filter(Boolean));

    const handleSelectSort = (event: SelectChangeEvent<string[]>) => {
        const sortValueFromEvent = event.target.value as string[];

        // map set to allow latest section (based on direction) to take precedence for key duplicates
        const sortSetMap = new Map<string, OrderByWithLabel>();
        sortValueFromEvent.forEach((sortOption) => {
            const correspondingOption = sortingOptions.find(({ sortBy, labelTKey }) => labelTKey === sortOption);
            if (correspondingOption) {
                sortSetMap.set(correspondingOption.sortBy, correspondingOption);
            }
        });

        const updatedSortValueArray = Array.from(sortSetMap.values()).map(({ labelTKey }) => labelTKey);
        const updatedSortValueCommaDelimited = updatedSortValueArray.join(',');

        if (updatedSortValueCommaDelimited !== defaultValue) {
            setSortValue(updatedSortValueArray);
        }
    };

    useEffect(() => {
        const selectedSortOptions = sortValue
            .map((sortOption) => sortingOptions.find(({ labelTKey }) => labelTKey === sortOption))
            .filter(Boolean) as OrderByWithLabel[];

        if (selectedSortOptions.length === 0) {
            return;
        }

        const updatedSortingData: SortingQueryParamMapping = selectedSortOptions.reduce<SortingQueryParamMapping>(
            (acc, option) => {
                const currentSorts = acc.sortBy.split(',').filter(Boolean);
                const currentDirs = acc.sortDirection.split(',').filter(Boolean);

                acc.sortBy = [...currentSorts, option.sortBy].join(',');
                acc.sortDirection = [...currentDirs, option.sortDirection].join(',');
                acc.uiSelectorValue = [...acc.uiSelectorValue.split(',').filter(Boolean), option.labelTKey].join(',');
                return acc;
            },
            {
                sortBy: '',
                sortDirection: '',
                uiSelectorValue: ''
            }
        );

        onSortChange(updatedSortingData);
    }, [onSortChange, sortValue, sortingOptions]);

    const sortOptionsMarkup = useMemo(
        () =>
            sortingOptions.map(({ labelTKey }) => (
                <MenuItem key={labelTKey} value={labelTKey}>
                    <Typography variant="body2">{t(`common:sorting.${labelTKey}`) ?? ''}</Typography>
                </MenuItem>
            )),
        [sortingOptions, t]
    );

    return (
        <FormControl fullWidth sx={{ position: 'relative' }}>
            <InputLabel>{t('common:sorting.sorting')}</InputLabel>
            <Select
                className="list-sort-selector"
                startAdornment={
                    sortValue.length > 0 && (
                        <InputAdornment sx={{ position: 'relative' }} position="end">
                            <IconButton
                                aria-label={t('common:sorting.clear_sorting_options')}
                                title={t('common:sorting.clear_sorting_options')}
                                onClick={() => setSortValue([])}
                                sx={{
                                    '&:hover > .clear-sorting-options-btn': {
                                        color: 'error.main'
                                    }
                                }}
                            >
                                <ClearIcon
                                    className="clear-sorting-options-btn"
                                    sx={{
                                        position: 'absolute',
                                        color: 'divider',
                                        top: '-25px',
                                        left: '-45px',
                                        fontSize: '0.9em',
                                        zIndex: 1
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }
                label={t('common:sorting.sorting')}
                fullWidth
                multiple
                open={isSortSelectorOpen}
                onChange={handleSelectSort}
                onClose={() => setIsSortSelectorOpen(false)}
                onOpen={() => setIsSortSelectorOpen(true)}
                renderValue={(sortTKey: string[]) => {
                    const sortCount = sortValue.length;
                    return (
                        <>
                            {sortTKey.map((sortTKey: string, index: number) => (
                                <Typography key={sortTKey} variant="caption" component="div">
                                    {t(`common:sorting.${sortTKey}`)}
                                    {index < sortCount - 1 ? ', ' : ''}
                                </Typography>
                            ))}
                        </>
                    );
                }}
                value={sortValue}
            >
                {sortOptionsMarkup}
            </Select>
        </FormControl>
    );
};
