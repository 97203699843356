import { OrderByWithLabel } from '@bladebinge/types';

export const LISTING_SORT_OPTIONS: OrderByWithLabel[] = [
    {
        labelTKey: 'newest',
        sortBy: 'createdAt',
        sortDirection: 'DESC'
    },
    {
        labelTKey: 'oldest',
        sortBy: 'createdAt',
        sortDirection: 'ASC'
    },
    {
        labelTKey: 'title_a_to_z',
        sortBy: 'title',
        sortDirection: 'ASC'
    },
    {
        labelTKey: 'title_z_to_a',
        sortBy: 'title',
        sortDirection: 'DESC'
    },
    {
        labelTKey: 'price_highest_to_lowest',
        sortBy: 'price',
        sortDirection: 'DESC'
    },
    {
        labelTKey: 'price_lowest_to_highest',
        sortBy: 'price',
        sortDirection: 'ASC'
    },
    {
        labelTKey: 'quantity_available_highest_to_lowest',
        sortBy: 'quantityAvailable',
        sortDirection: 'DESC'
    },
    {
        labelTKey: 'quantity_available_lowest_to_highest',
        sortBy: 'quantityAvailable',
        sortDirection: 'ASC'
    }
];
