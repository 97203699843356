import { PaginatedObjectionListResults } from '@bladebinge/types';

export const defaultGetNextPageParam = <T>(lastPage: PaginatedObjectionListResults<T>) => {
    const { _total = 0, _limit = 0, _offset = 0 } = lastPage;
    const nextPageOffset = _offset + _limit;
    const hasMore = nextPageOffset < _total;

    return hasMore ? { _offset: `${nextPageOffset}` } : undefined;
};

export const defaultGetPreviousPageParam = <T>(firstPage: PaginatedObjectionListResults<T>) => {
    const { _limit = 0, _offset = 0 } = firstPage;
    const previousPageOffset = _offset - _limit > 0 ? _offset - _limit : undefined;

    return previousPageOffset ? { _offset: `${previousPageOffset}` } : undefined;
};
