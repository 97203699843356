import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import Box from '@mui/material/Box';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import SellIcon from '@mui/icons-material/Sell';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { headingFont } from '@bladebinge/web-service-common/src/constants/fonts';

const StyledHelpLink = styled(Link)(({ theme }) => ({
    display: 'block',
    fontSize: '1.4em',
    fontFamily: headingFont.style.fontFamily,
    color: theme.palette.primary.main,
    '&:hover': {
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        textDecoration: 'none'
    },
    marginTop: theme.spacing(1)
}));

export const StackedHelpLinks = () => {
    const { t } = useTranslation();

    return (
        <Box sx={{ p: 1 }}>
            <StyledHelpLink target="_blank" href="https://intercom.help/bladebinge/en/articles/9010873-buyer-s-guide">
                <ShoppingBagIcon sx={{ position: 'relative', top: '4px', mr: 1 }} />
                {t('cms:help.buyers_guide')}
            </StyledHelpLink>
            <StyledHelpLink target="_blank" href="https://intercom.help/bladebinge/en/articles/9011002-seller-s-guide">
                <SellIcon sx={{ position: 'relative', top: '4px', mr: 1 }} />
                {t('cms:help.sellers_guide')}
            </StyledHelpLink>
            <StyledHelpLink target="_blank" href="https://intercom.help/bladebinge/en">
                <HelpCenterIcon sx={{ position: 'relative', top: '4px', mr: 1 }} />
                {t('cms:help.help_center')}
            </StyledHelpLink>
        </Box>
    );
};
