import type { ParsedUrlQuery } from 'querystring';
import { useInfiniteQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { ListingGraph, PaginatedObjectionListResults, UiQueryCacheKey } from '@bladebinge/types';
import { getRecentlySoldListings } from '../../server/api-proxy/listings';
import { defaultGetNextPageParam, defaultGetPreviousPageParam } from './infinite-query-defaults';

const fetchRecentlySoldListingsFn =
    ({ cacheKey, query }: { cacheKey: UiQueryCacheKey; query?: ParsedUrlQuery }) =>
    async ({
        pageParam
    }: {
        pageParam?: { _offset?: string };
    }): Promise<PaginatedObjectionListResults<ListingGraph>> => {
        if (!pageParam || pageParam?._offset === undefined) {
            throw new Error('User ID is required to fetch recently sold listings');
        }

        const response = await getRecentlySoldListings({
            query: {
                ...query,
                offset: `${pageParam._offset}`
            }
        });

        const { error: { message: fetchError = '' } = {} } = response as { error?: { message: string } };

        if (fetchError) {
            throw new Error(fetchError);
        }

        return {
            cacheKey,
            ...response
        };
    };

export const useRecentlySoldListings = ({
    query,
    q = '',
    userListingSortByData = {
        sortBy: '',
        sortDirection: '',
        uiSelectorValue: ''
    }
}: {
    query: ParsedUrlQuery;
    q?: string;
    userListingSortByData?: {
        sortBy: string;
        sortDirection: string;
        uiSelectorValue: string;
    };
}) => {
    const { uiSelectorValue, ...sortParams } = userListingSortByData;
    const queryParams = {
        ...query,
        ...sortParams,
        q
    };
    const cacheKey = uiCacheKeyBuilderMap.recentlySoldListings({
        query: queryParams
    });

    return useInfiniteQuery({
        enabled: true,
        initialPageParam: query?.offset === undefined ? { _offset: '0' } : { _offset: `${query.offset}` },
        queryKey: cacheKey,
        queryFn: fetchRecentlySoldListingsFn({ cacheKey, query: queryParams }),
        placeholderData: (prev) => prev,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always',
        getNextPageParam: defaultGetNextPageParam,
        getPreviousPageParam: defaultGetPreviousPageParam
    });
};
