import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

export const NoResults = ({
    alternateActionComponent = null
}: {
    readonly alternateActionComponent?: React.ReactNode | null;
}) => {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontSize: '1.5rem', fontStyle: 'italic', textAlign: 'center' }}>
                    {t('common:general.no_results')}
                </Typography>
            </Grid>
            {alternateActionComponent && (
                <Grid item xs={12} sx={{ textAlign: 'center', my: 1 }}>
                    {alternateActionComponent}
                </Grid>
            )}
        </Grid>
    );
};
