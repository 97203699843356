import React from 'react';
import { default as NextImage } from 'next/image';
import Link from 'next/link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { ListingGraph } from '@bladebinge/types';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { slugFromIdAndText } from '@bladebinge/web-service-common/src/utils/listings/slug-from-id-and-text';
import Grid from '@mui/material/Grid';
import { truncateString } from '../../utils/truncate-string';
import { PurchaseButtons } from '../atoms/PurchaseButtons';
import { StyledTitleAnchor } from '../styled-shared';
import { FavoriteIconButton } from '../atoms/FavoriteIconButton';
import { ListingDisplayPrice } from './ListingDisplayPrice';

export const ListingTile = ({ listing }: { readonly listing: ListingGraph }) => {
    const primaryImage = (listing?.images || []).find(({ isPrimary }) => isPrimary);

    const listingUrl = `/listings/${slugFromIdAndText(listing?.id ?? '', listing.title)}`;
    return (
        <Grid
            item
            container
            justifyContent="space-between"
            spacing={1}
            xs={12}
            sx={{
                height: '100%'
            }}
        >
            <Grid item xs={12} sx={{ alignContent: 'flex-start' }}>
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        aspectRatio: 1,
                        '& a': { display: 'block' },
                        '& img': {
                            borderRadius: '4px'
                        }
                    }}
                >
                    <Link href={listingUrl} title={listing.title}>
                        <NextImage alt={listing?.title} src={getCdnUrl(primaryImage?.thumbnailUrl)} fill priority />
                    </Link>
                </Box>
                <Typography variant="h6" component="div" sx={{ fontSize: '1em', height: '52px', overflow: 'hidden' }}>
                    <StyledTitleAnchor href={listingUrl} title={listing.title}>
                        {truncateString(listing.title, 65)}
                    </StyledTitleAnchor>
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item>
                        <ListingDisplayPrice
                            price={listing.price}
                            currency={listing.currency}
                            sx={{ textAlign: 'center' }}
                        />
                    </Grid>
                    <Grid item sx={{ paddingLeft: 0.35 }}>
                        <FavoriteIconButton listing={listing} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ alignContent: 'flex-end' }}>
                <PurchaseButtons listing={listing} ownerId={listing?.ownerId} size="small" />
            </Grid>
        </Grid>
    );
};
