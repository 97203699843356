import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { SortingQueryParamMapping } from '@bladebinge/types';
import styled from '@emotion/styled';
import { SortingOptionsSelector } from './SortingOptionsSelector';
import { LISTING_SORT_OPTIONS } from './listing/listing-sort-options';
import { GroupedListingSearchInput } from './GroupedListingSearchInput';

const MobileSearchToggle = styled(IconButton)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('sm')]: {
        display: 'inline'
    }
}));

const SearchWrapper = styled(Grid)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
        '&.show-search-input': {
            display: 'block'
        }
    }
}));

export const GroupedListingsSearchAndSort = ({
    onChange,
    groupId,
    enableMobileSearchToggle = false
}: {
    readonly onChange: (params: { q: string; userListingSortByData: SortingQueryParamMapping }) => void;
    readonly groupId: string;
    readonly enableMobileSearchToggle?: boolean;
}) => {
    const { t } = useTranslation();
    const [q, setQ] = useState<string>('');
    const [userListingSortByData, setUserListingSortByData] = useState<SortingQueryParamMapping>({
        sortBy: '',
        sortDirection: '',
        uiSelectorValue: ''
    });
    const [showMobileSearchBar, setShowMobileSearchBar] = useState<boolean>(false);

    useEffect(() => {
        onChange({
            q,
            userListingSortByData
        });
    }, [q, onChange, userListingSortByData]);

    return (
        <Grid
            container
            spacing={1}
            justifyContent={{
                xs: 'flex-start',
                sm: 'space-between'
            }}
            flexDirection={{
                xs: 'column-reverse',
                sm: 'row'
            }}
        >
            <SearchWrapper
                className={enableMobileSearchToggle && showMobileSearchBar ? 'show-search-input' : ''}
                item
                xs={12}
                sm={6}
            >
                <GroupedListingSearchInput onSearchChange={setQ} groupId={groupId} />
            </SearchWrapper>
            <Grid
                item
                container
                justifyContent="flex-end"
                xs={12}
                sm={6}
                sx={{
                    mt: {
                        xs: 0.75,
                        sm: 0
                    },
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {enableMobileSearchToggle && (
                    <Grid item xs={2}>
                        <MobileSearchToggle
                            aria-label={
                                showMobileSearchBar
                                    ? t('common:navigation.hide_mobile_search')
                                    : t('common:navigation.show_mobile_search')
                            }
                            onClick={() => setShowMobileSearchBar(!showMobileSearchBar)}
                            title={
                                showMobileSearchBar
                                    ? t('common:navigation.hide_mobile_search')
                                    : t('common:navigation.show_mobile_search')
                            }
                            color="inherit"
                            size="small"
                        >
                            <SearchIcon />
                        </MobileSearchToggle>
                    </Grid>
                )}
                <Grid item xs={9} sm={11}>
                    <SortingOptionsSelector
                        onSortChange={setUserListingSortByData}
                        defaultValue={userListingSortByData.uiSelectorValue}
                        sortingOptions={LISTING_SORT_OPTIONS}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
