export const truncateString = (str: string = '', maxChars: number): string => {
    // just return if str is falsy
    if (!str) {
        return str;
    }

    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= maxChars) {
        return str;
    }

    // Return str truncated with '...' concatenated to the end of str.
    return `${str.slice(0, maxChars)}...`;
};
