import type { ParsedUrlQuery } from 'querystring';
import { useInfiniteQuery } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import {
    ListingGraph,
    PaginatedObjectionListResults,
    SortingQueryParamMapping,
    UiQueryCacheKey
} from '@bladebinge/types';
import { getUserFavoriteListings } from '../../../server/api-proxy/user-favorite-listings';
import { defaultGetNextPageParam, defaultGetPreviousPageParam } from '../infinite-query-defaults';

const userFetchHomePageFavoriteListingsQueryFn =
    ({ cacheKey, query, userId }: { cacheKey: UiQueryCacheKey; query?: ParsedUrlQuery; userId?: string | null }) =>
    async ({
        pageParam
    }: {
        pageParam?: { _offset?: string };
    }): Promise<PaginatedObjectionListResults<ListingGraph>> => {
        if (!userId || !pageParam || pageParam?._offset === undefined) {
            throw new Error('User ID is required to fetch user favorite listings');
        }

        const response = await getUserFavoriteListings({
            query: {
                ...query,
                offset: `${pageParam._offset}`
            },
            userId
        });

        const { error: { message: fetchError = '' } = {} } = response as { error?: { message: string } };

        if (fetchError) {
            throw new Error(fetchError);
        }

        return {
            cacheKey,
            ...response
        };
    };

export const useUserHomePageFavoriteListings = ({
    q = '',
    query,
    showSoldOutItems = false,
    userId,
    userListingSortByData = {
        sortBy: '',
        sortDirection: '',
        uiSelectorValue: ''
    }
}: {
    q?: string;
    query: ParsedUrlQuery;
    showSoldOutItems?: boolean;
    userId?: string | null;
    userListingSortByData?: SortingQueryParamMapping;
}) => {
    const { uiSelectorValue, ...sortParams } = userListingSortByData;
    const queryParams = {
        ...query,
        ...sortParams,
        q,
        includeSold: `${showSoldOutItems}`
    };
    const cacheKey = uiCacheKeyBuilderMap.loggedInUserHomePageFavoriteListings({
        loggedInUserId: userId,
        query: queryParams
    });

    return useInfiniteQuery({
        enabled: Boolean(userId),
        initialPageParam: query?.offset === undefined ? { _offset: '0' } : { _offset: `${query.offset}` },
        queryKey: cacheKey,
        queryFn: userFetchHomePageFavoriteListingsQueryFn({ cacheKey, query: queryParams, userId }),
        placeholderData: (prev) => prev,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always',
        getNextPageParam: defaultGetNextPageParam,
        getPreviousPageParam: defaultGetPreviousPageParam
    });
};
