import React, { useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { LoadingIndicator } from '../atoms/LoadingIndicator';
import { NoResults } from '../atoms/NoResults';
import { ListingTile } from '../listing/ListingTile';
import { Pagination } from '../atoms/Pagination';
import { useMe } from '../../context/me/me-context';
import { useUserHomePageFavoriteListings } from '../../hooks/react-query/logged-in-user-hooks/use-user-home-page-favorite-listings';
import { GroupedListingsSearchAndSort } from '../FormComponents/GroupedListingsSearchAndSort';
import { useUserViewedListings } from '../../hooks/react-query/logged-in-user-hooks/use-user-viewed-listings';

export const HomePageListingGroup = ({
    dataHook,
    groupId,
    noResultsTKey,
    titleTKey
}: {
    readonly dataHook:
        | typeof useUserHomePageFavoriteListings
        | typeof useUserViewedListings
        | typeof useUserHomePageFavoriteListings;
    readonly groupId: string;
    readonly noResultsTKey: string;
    readonly titleTKey: string;
}) => {
    const { t } = useTranslation();
    const router = useRouter();
    const { id: loggedInUserId } = useMe();
    const { query } = router;
    const [searchAndSortParams, setSearchAndSortParams] = useState({
        q: (query?.q as string) ?? '',
        userListingSortByData: {
            sortBy: '',
            sortDirection: '',
            uiSelectorValue: ''
        }
    });

    const {
        data,
        isLoading: loadingListings,
        isPending: pendingListings,
        error: loadListingsError,
        refetch
    } = dataHook({
        query: {
            ...query,
            limit: '25',
            includeSold: 'false'
        },
        userId: loggedInUserId,
        ...searchAndSortParams
    });

    const lastPageFetched = useMemo(() => (Array.isArray(data?.pages) ? data.pages.length - 1 : 0), [data]);
    const currentPageData = useMemo(
        () => data?.pages?.[lastPageFetched] ?? { result: [], _limit: 25, _total: 0 },
        [data, lastPageFetched]
    );
    const { _total = 0, _limit = 25, result: currentPageItems = [] } = currentPageData;
    const itemXsGridWidth = useMemo(() => (_total >= 2 ? 6 : 12), [_total]);

    if (!loggedInUserId) {
        return null;
    }

    return (
        <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={12}>
                <Typography variant="h6" component="div">
                    {t(titleTKey)}
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
                <GroupedListingsSearchAndSort
                    onChange={setSearchAndSortParams}
                    groupId={groupId}
                    enableMobileSearchToggle
                />
            </Grid>
            <Grid item xs={12}>
                {loadListingsError && (
                    <Alert severity="error" onClose={() => refetch()}>
                        {loadListingsError.message}
                    </Alert>
                )}
                {(loadingListings || pendingListings) && <LoadingIndicator />}
            </Grid>
            <Grid container item xs={12} spacing={1}>
                <Box
                    sx={{
                        borderStyle: 'solid',
                        borderColor: 'divider',
                        borderWidth: '1px',
                        borderRadius: '4px',
                        boxSizing: 'border-box',
                        padding: 1.5,
                        overflowX: 'auto', // Enable horizontal scrolling
                        display: 'flex',
                        flexDirection: 'row',
                        flexGrow: 1 // Allow the Box to grow and take available space
                    }}
                >
                    <Grid container spacing={1} wrap="nowrap">
                        {currentPageItems.length > 0 ? (
                            currentPageItems.map((listingGraph) => (
                                <Grid
                                    className="listing-group-items"
                                    item
                                    xs={itemXsGridWidth}
                                    key={listingGraph.id}
                                    sx={{ flex: '0 0 auto' }}
                                >
                                    <ListingTile listing={listingGraph} />
                                </Grid>
                            ))
                        ) : (
                            <NoResults
                                alternateActionComponent={
                                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                        {t(noResultsTKey)}
                                    </Typography>
                                }
                            />
                        )}
                    </Grid>
                </Box>
            </Grid>
            {_total > _limit && (
                <Grid container justifyContent="center" sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <Pagination direction="row" result={currentPageItems} total={_total} limitPreference={_limit} />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
