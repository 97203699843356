import React from 'react';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';

const StyledLoadingWrap = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1, 0)
}));

interface LoadingProps {
    readonly className?: string;
    readonly color?: 'primary' | 'inherit';
    readonly inline?: boolean;
    readonly size?: number;
}

export const LoadingIndicator = ({ className = '', color = 'primary', inline = false, size = 40 }: LoadingProps) =>
    inline ? (
        <CircularProgress className={className} color={color} size={size} />
    ) : (
        <StyledLoadingWrap className={className}>
            <CircularProgress color={color} size={size} />
        </StyledLoadingWrap>
    );
