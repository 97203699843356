import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import debounce from 'lodash.debounce';
import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import { useHasMounted } from '@bladebinge/next-ui/hooks/use-has-mounted';

const StyledSearchWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    height: '35px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.2),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.divider
}));

export const GroupedListingSearchInput = ({
    onSearchChange,
    groupId
}: {
    readonly onSearchChange: (q: string) => void;
    readonly groupId: string;
}) => {
    const { t } = useTranslation();
    const hasMounted = useHasMounted();
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const debouncedSearchChange = debounce(setSearchInputValue, 1000, { leading: true, trailing: true });

    const handleClear = () => {
        setSearchInputValue('');
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!hasMounted) {
            return;
        }

        debouncedSearchChange(e.target.value);
    };

    const handleInputKeyUp = (e: React.KeyboardEvent) => {
        e.stopPropagation();

        if (e.key === 'Escape') {
            handleClear();
        }
    };

    useEffect(() => {
        onSearchChange(searchInputValue);
    }, [onSearchChange, searchInputValue]);

    return (
        <StyledSearchWrapper>
            <InputBase
                data-testid="binge-search"
                id={`${groupId}_profile_listing_search`}
                inputProps={{
                    'aria-label': t('common:navigation.search'),
                    variant: 'contained'
                }}
                fullWidth
                onChange={handleInputChange}
                onKeyUp={handleInputKeyUp}
                placeholder={t('common:navigation.search')}
                sx={{
                    color: 'inherit',
                    pt: 0.25,
                    px: 1,
                    pb: 1
                }}
                endAdornment={
                    <Grid container justifyContent="flex-end" sx={{ zIndex: 1, width: '100px', pt: 0.5 }}>
                        <Grid item>
                            {searchInputValue && (
                                <CancelOutlinedIcon
                                    color="secondary"
                                    onClick={handleClear}
                                    sx={{ cursor: 'pointer' }}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            <SearchIcon
                                sx={{
                                    cursor: 'pointer',
                                    zIndex: 1,
                                    mr: 0.5,
                                    ml: 0.5
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                value={searchInputValue}
            />
        </StyledSearchWrapper>
    );
};
